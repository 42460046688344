import React from "react";
import { wordedLogo } from "../constants/import";

const Spinner = () => {
  return (
    <div className="loadingSpinnerContainer">
      <div className="flex items-center justify-center">
        <img
          className="spinning-image "
          alt="Logo"
          src="https://firstmultiplemfbank.com/sclnew/wp-content/uploads/2022/09/cropped-fav-1.png"
        />
        <img src={wordedLogo} alt="Logo" className="w-[200px]" />
      </div>
    </div>
  );
};

export default Spinner;
